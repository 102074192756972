import { Backdrop, Button, Fade, IconButton, Modal } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, {
    Ref,
    forwardRef,
    useImperativeHandle,
    useState,
} from "react";
import Close from "@mui/icons-material/Close";
import styles from "./UpgradePlanModal.module.scss";
import useWindowSize from "Hooks/useWindowSize";
import { useHistory, useParams } from "react-router-dom";
import { END_FREE_TRIAL_END, NEXT_PLAN_UPGRADE } from "../UpgradePlan";

const useStyles = makeStyles({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
});

export interface IUpgradePlanModalRef {
    setIsVisible: (visible: boolean) => void;
    isVisible: boolean;
}

interface IUpgradePlanModalProps {}

const UpgradePlanModal = (
    props: IUpgradePlanModalProps,
    ref: Ref<IUpgradePlanModalRef>
) => {
    const history = useHistory();
    const params = useParams<{ id: string }>();
    const isDesktop = useWindowSize().width > 600;
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const classes = useStyles();

    const handleClose = () => {
        setIsVisible(false);
        sessionStorage.removeItem(NEXT_PLAN_UPGRADE);
        sessionStorage.removeItem(END_FREE_TRIAL_END);
        history.push(`/membership/${params.id}`);
    };

    useImperativeHandle(
        ref,
        () => ({
            isVisible,
            setIsVisible,
        }),
        [isVisible]
    );

    return (
        <Modal
            open={isVisible}
            onClose={handleClose}
            className={classes.modal}
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={isVisible}>
                <div className={styles["upgrade_plan_modal"]}>
                    <div className={styles["upgrade_plan_modal-header"]}>
                        <IconButton
                            onClick={handleClose}
                            className="close-button"
                        >
                            <Close />
                        </IconButton>
                    </div>
                    <div className={styles["upgrade_plan_modal-content"]}>
                        <div className={styles["upgrade_plan_modal-img"]}>
                            <img
                                src="/images/membership/claim-success.png"
                                alt="Aviron Membership Claimed"
                            />
                        </div>
                        <h3 className={styles["upgrade_plan_modal-title"]}>
                            ALL SET!
                        </h3>
                        <p className={styles["upgrade_plan_modal-desc"]}>
                            Your membership has been updated.{" "}
                            {isDesktop && <br />}
                            We can’t wait to see what you’ll achieve.
                        </p>
                        <div className={styles["upgrade_plan_modal-button"]}>
                            <Button
                                variant="contained"
                                onClick={handleClose}
                                color="secondary"
                                sx={{
                                    width: {
                                        xs: "100%",
                                        sm: "240px",
                                        md: "360px",
                                    },
                                }}
                            >
                                OK
                            </Button>
                        </div>
                    </div>
                </div>
            </Fade>
        </Modal>
    );
};

export default forwardRef(UpgradePlanModal);
