import React, { useEffect, useMemo, useRef, useState } from "react";
import { useUpgradeMembershipPlan } from "./hook";
import useGetMembershipDetail from "Hooks/MembershipHook/MembershipDetail.hook";
import { useHistory, useParams } from "react-router-dom";
import { Box, Button, Checkbox, MenuItem, Typography } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import moment from "moment";
import { usePlansQuery } from "services/webServer";
import { AvironLoading } from "Components/Icons/AvironLoading";
import { MEMBERSHIP_INTERVAL, MembershipProductType } from "consts/membership";
import UpgradePlanModal, { IUpgradePlanModalRef } from "./UpgradePlanModal";
import { CONSTANTS } from "consts";
import GoBack from "Components/GoBack";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";

import styles from "./UpgradePlan.module.scss";

export const NEXT_PLAN_UPGRADE = "nextPlanUpgrade";
export const END_FREE_TRIAL_END = "endFreeTrialDate";

const MembershipUpgradePlanPage = () => {
    const params = useParams<{ id: string }>();
    const { gameMembershipDetail, loading, webMembershipDetail } =
        useGetMembershipDetail(params.id);

    return loading ? (
        <AvironLoading />
    ) : (
        <UpgradeMembership
            gameMembershipDetail={gameMembershipDetail}
            webMembershipDetail={webMembershipDetail}
        />
    );
};

const UpgradeMembership: React.FC<{
    gameMembershipDetail: any;
    webMembershipDetail: any;
}> = ({ gameMembershipDetail, webMembershipDetail }) => {
    const { availableBillingCycles, isFreeMembership, currentPlan } =
        useUpgradeMembershipPlan({
            gameMembershipDetail,
            webMembershipDetail,
        });
    const params = useParams<{ id: string }>();

    const history = useHistory();
    const { data: planData, loading: loadingPlan } = usePlansQuery();
    const upgradePlanRef = useRef<IUpgradePlanModalRef>(null);

    const [selected, setSelected] = useState<number>(0);
    const [isLifetimeWarranty, setIsLifetimeWarranty] =
        useState<boolean>(false);
    const [isShowOtherOptions, setIsShowOtherOptions] =
        useState<boolean>(false);
    const [selectedMachine, setSelectedMachine] = useState<number>(1);

    const endFreeTrialDate = useMemo(() => {
        if (!isFreeMembership) return moment(new Date());
        if (webMembershipDetail?.currentPeriodEnd) {
            return moment(webMembershipDetail.currentPeriodEnd);
        }
        return moment(webMembershipDetail.createdAt).add(30, "days");
    }, [webMembershipDetail]);

    const expireLifeTimeWarranty = useMemo(() => {
        if(!webMembershipDetail) return true;
        const expireDate = moment(webMembershipDetail?.startDay).add(35, "days");
        return expireDate.isBefore(moment());
    },[webMembershipDetail])

    const isActiveStatus = useMemo(() => {
        if (!webMembershipDetail) return false;
        return webMembershipDetail?.state === "active";
    }, [webMembershipDetail]);

    const currentMachineCount = useMemo(() => {
        if (!webMembershipDetail) return 1;
        setSelectedMachine(webMembershipDetail?.plan?.maxMachines || 1);
        return webMembershipDetail?.plan?.maxMachines || 1;
    }, [webMembershipDetail]);

    const productId = useMemo(() => {
        if (
            webMembershipDetail?.plan?.productId ===
            MembershipProductType.membershipWithWarranty
        ) {
            return MembershipProductType.membershipWithWarranty;
        }

        return isFreeMembership
            ? isLifetimeWarranty
                ? MembershipProductType.membershipWithWarranty
                : MembershipProductType.membership
            : MembershipProductType.membership;
    }, [isFreeMembership, isLifetimeWarranty, webMembershipDetail]);

    const nextPlanData = useMemo(() => {
        const currentTypeSelected = availableBillingCycles[selected]?.interval;
        const isTwoYears =
            availableBillingCycles[selected]?.label === "2 YEARS";
        const isGoModel = webMembershipDetail?.plan?.appIds?.length > 0 ? webMembershipDetail?.plan?.appIds?.includes(CONSTANTS.APP.AVIRON_GO) : false

        if (!webMembershipDetail) {
            return;
        }
        if (isTwoYears) {
            const isCurrentPlanTwoYears = webMembershipDetail?.plan?.name?.includes("2 Year Family Membership");
            if (selectedMachine <= webMembershipDetail?.plan?.maxMachines && isCurrentPlanTwoYears) {
                return;
            }
            return planData?.plans?.nodes?.find(
                (plan) =>
                    plan.interval === "yearly" &&
                    plan.intervalCount === 2 &&
                    plan.maxMachines === selectedMachine &&
                    plan.productId === productId &&
                    plan.name?.includes("2 Year Family Membership")
            );
        }
        if(isGoModel) {
            return planData?.plans?.nodes?.find(
                (plan) =>
                    plan.interval === "yearly" &&
                    plan.intervalCount === 1 &&
                    plan.maxMachines === 1 &&
                    plan.productId === productId &&
                    plan.appIds?.includes(CONSTANTS.APP.AVIRON_GO) && plan.currency === webMembershipDetail.plan.currency
            );
        }

        if (currentTypeSelected === webMembershipDetail?.plan?.interval) {
            if (
                selectedMachine <= webMembershipDetail?.plan?.maxMachines &&
                Boolean(!isFreeMembership)
            ) {
                return;
            }
            return planData?.plans?.nodes?.find(
                (plan) =>
                    plan.interval === webMembershipDetail?.plan?.interval &&
                    plan.maxMachines === selectedMachine &&
                    plan.productId === productId && plan.currency === webMembershipDetail.plan.currency
            );
        }
        return planData?.plans?.nodes?.find(
            (plan) =>
                plan.interval === currentTypeSelected &&
                plan.maxMachines === selectedMachine &&
                plan.productId === productId && plan.currency === webMembershipDetail.plan.currency
        );
    }, [
        webMembershipDetail,
        planData,
        isFreeMembership,
        selected,
        selectedMachine,
        isLifetimeWarranty,
        productId,
    ]);

    console.log("nextPlanData", nextPlanData);

    const handleCalculatePrice = (planDetail: {
        key: string;
        interval: MEMBERSHIP_INTERVAL;
        intervalCount: number;
        label: string;
        description: string;
        price: string;
        unit: string;
        special: string;
    }) => {
        if (!planData) return 0;
        if (planDetail.interval === MEMBERSHIP_INTERVAL.MONTHLY) {
            const price =
                planData?.plans?.nodes?.find(
                    (plan) =>
                        plan.interval === "monthly" &&
                        plan.maxMachines === selectedMachine
                )?.amount || 0;
            return price;
        }

        // YEARLY
        if (planDetail.intervalCount === 2) {
            // 2 years
            const price =
                planData?.plans?.nodes?.find(
                    (plan) =>
                        plan.interval === "yearly" &&
                        plan.intervalCount === 2 &&
                        plan.maxMachines === selectedMachine &&
                        plan.name?.includes("2 Year Family Membership")
                )?.amount || 0;
            // Divide by 24 to get monthly price
            return price / 24;
        }

        const price =
            planData?.plans?.nodes?.find(
                (plan) =>
                    plan.interval === "yearly" &&
                    plan.maxMachines === selectedMachine
            )?.amount || 0;
        return price / 12;
    };

    const handleNextChangePlan = () => {
        sessionStorage.setItem(NEXT_PLAN_UPGRADE, JSON.stringify(nextPlanData));
        sessionStorage.setItem(END_FREE_TRIAL_END, endFreeTrialDate.toString());
        history.push(
            `${CONSTANTS.NAVIGATION_URL.MEMBERSHIP_UPGRADE_PLAN_CONFIRM.replace(
                ":id",
                params?.id || ""
            )}`
        );
    };

    useEffect(() => {
        setIsShowOtherOptions(isFreeMembership);
    }, [isFreeMembership]);

    useEffect(() => {
        if(isFreeMembership && webMembershipDetail?.state === "inactive") {
            history.push(CONSTANTS.NAVIGATION_URL.MEMBERSHIP_DETAIL.replace(":id", params?.id || ""));
        }
    }, [isFreeMembership, webMembershipDetail])

    if (!webMembershipDetail || loadingPlan) {
        return <AvironLoading />;
    }
  
    return (
        <Box
            sx={{
                marginTop: "24px",
            }}
        >
            <GoBack />

            <Box
                sx={{
                    padding: {
                        xs: "12px",
                        md: "0px",
                    },
                    marginTop: {
                        xs: "12px",
                        md: "24px",
                    },
                }}
            >
                <Box
                    sx={{
                        backgroundColor: "white",
                        padding: {
                            xs: "16px 12px",
                            md: "40px",
                        },
                        borderRadius: "8px",
                    }}
                >
                    <Typography
                        variant="h4"
                        sx={{
                            fontWeight: 700,
                            color: "#161616",
                            fontFamily: "GRIFTER",
                            letterSpacing: "0.01em",
                            fontSize: {
                                xs: "18px",
                                md: "20px",
                            },
                            marginBottom: {
                                xs: "16px",
                                md: "24px",
                            },
                        }}
                    >
                        YOUR CURRENT MEMBERSHIP
                    </Typography>

                    <Box
                        sx={{
                            backgroundColor: "#f4f4f4",
                            borderRadius: "8px",
                            padding: {
                                xs: "12px",
                                md: "22px 24px",
                            },
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: 600,
                                color: "#161616",
                                fontSize: {
                                    xs: "16px",
                                    md: "20px",
                                },
                                marginBottom: {
                                    xs: "12px",
                                    md: "8px",
                                },
                                textTransform: "uppercase",
                            }}
                        >
                            {webMembershipDetail.plan.name}
                        </Typography>
                        {isFreeMembership && isActiveStatus && (
                            <Typography
                                sx={{
                                    fontSize: "16px",
                                    fontWeight: 400,
                                }}
                            >
        
                                Free until {endFreeTrialDate.format("MMM D, YYYY")}. Your Membership will automatically transition to  {currentPlan?.name ||
                                    "Annual Family Membership"}.
                            </Typography>
                        )}
                    </Box>
                </Box>
                <Typography
                    sx={{
                        fontWeight: 300,
                        fontSize: {
                            xs: "24px",
                            md: "28px",
                        },
                        margin: {
                            xs: "24px 0 16px",
                            md: "24px 0",
                        },
                    }}
                >
                    CHANGE YOUR MEMBERSHIP
                </Typography>
                <Box
                    sx={{
                        backgroundColor: "#fff",
                        padding: {
                            xs: "16px 12px",
                            md: "40px",
                        },
                        borderRadius: "8px",
                        marginBottom: "20px",
                    }}
                >
                    <Box
                        sx={{
                            marginBottom: {
                                xs: "16px",
                                md: "24px",
                            },
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: {
                                    xs: "18px",
                                    md: "20px",
                                },
                                fontWeight: 700,
                                fontFamily: "GRIFTER",
                                lineHeight: "20px",
                                letterSpacing: "0.01em",
                            }}
                        >
                            I HAVE
                        </Typography>
                        <Select
                            value={selectedMachine.toString()}
                            onChange={(event: SelectChangeEvent) => {
                                setSelectedMachine(
                                    event.target.value as unknown as number
                                );
                            }}
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                            sx={{
                                margin: "0 16px",
                                "& .MuiSelect-select": {
                                    padding: "5px 16px",
                                },
                            }}
                        >
                            {Array.from(
                                { length: 10 - currentMachineCount + 1 },
                                (_, i) => currentMachineCount + i
                            ).map((item, idx) => (
                                <MenuItem key={idx} value={item}>
                                    {item}
                                </MenuItem>
                            ))}
                        </Select>
                        <Typography
                            sx={{
                                fontSize: {
                                    xs: "18px",
                                    md: "20px",
                                },
                                fontWeight: 700,
                                fontFamily: "GRIFTER",
                                lineHeight: "20px",
                                letterSpacing: "0.01em",
                            }}
                        >
                            MACHINE
                        </Typography>
                    </Box>
                    <Typography
                        sx={{
                            fontWeight: 700,
                            fontFamily: "GRIFTER",
                            letterSpacing: "0.01em",
                            fontSize: {
                                xs: "18px",
                                md: "20px",
                            },
                            marginBottom: {
                                xs: "16px",
                                md: "24px",
                            },
                        }}
                    >
                        SELECT BILLING CYCLE
                    </Typography>
                    {availableBillingCycles.map((cycle, idx) => {
                        const isSelected = selected === idx;
                        const isFirstItem = idx === 0;
                        const isLastItem =
                            idx === availableBillingCycles.length - 1;
                        if (
                            isFreeMembership &&
                            isLastItem &&
                            isShowOtherOptions
                        ) {
                            return null;
                        }
                        return (
                            <Box
                                key={cycle.key}
                                sx={{
                                    marginBottom: {
                                        xs: isLastItem ? "0px" : "16px",
                                        md: isLastItem ? "0px" : "24px",
                                    },
                                    borderRadius: "8px",
                                    cursor: "pointer",
                                    width: {
                                        xs: "100%",
                                        md: "60%",
                                    },
                                }}
                                onClick={() => {
                                    setSelected(idx);
                                }}
                            >
                                {isFirstItem && (
                                    <Box
                                        sx={{
                                            background:
                                                "linear-gradient(360deg, #F5490F 24.22%, #F57D0F 100%)",
                                            padding: "6px 0",
                                            borderTopLeftRadius: "8px",
                                            borderTopRightRadius: "8px",
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                color: "#fff",
                                                textAlign: "center",
                                                fontWeight: 600,
                                                fontSize: {
                                                    xs: "12px",
                                                    md: "16px",
                                                },
                                            }}
                                        >
                                            BEST VALUE
                                        </Typography>
                                    </Box>
                                )}

                                <Box
                                    sx={{
                                        border: isSelected
                                            ? "2px solid #FF4106"
                                            : "1px solid #C3C3C3",
                                        borderBottomLeftRadius: "8px",
                                        borderBottomRightRadius: "8px",
                                        borderTopLeftRadius:
                                            isSelected && isFirstItem
                                                ? "0px"
                                                : isFirstItem
                                                ? "0px"
                                                : "8px",
                                        borderTopRightRadius:
                                            isSelected && isFirstItem
                                                ? "0px"
                                                : isFirstItem
                                                ? "0px"
                                                : "8px",

                                        display: "flex",
                                        flexDirection: {
                                            xs: "column",
                                            sm: "row",
                                        },
                                        justifyContent: "space-between",
                                        alignItems: {
                                            xs: "flex-start",
                                            sm: "center",
                                        },
                                        padding: {
                                            xs: "16px 12px",
                                            md: "24px",
                                        },
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            width: "100%",
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: {
                                                    xs: "none",
                                                    sm: "block",
                                                },
                                            }}
                                        >
                                            <Checkbox
                                                checked={isSelected}
                                                sx={{
                                                    padding: "0",
                                                }}
                                                checkedIcon={
                                                    <Box
                                                        sx={{
                                                            width: "24px",
                                                            height: "24px",
                                                        }}
                                                    >
                                                        <img
                                                            src="/images/icon-check-primary.png"
                                                            alt="aviron membership"
                                                            style={{
                                                                width: "100%",
                                                                height: "100%",
                                                                objectFit:
                                                                    "cover",
                                                            }}
                                                        />
                                                    </Box>
                                                }
                                                icon={
                                                    <RadioButtonUncheckedIcon
                                                        sx={{
                                                            width: "24px",
                                                            height: "24px",
                                                        }}
                                                    />
                                                }
                                            />
                                        </Box>

                                        <Box
                                            sx={{
                                                marginLeft: {
                                                    xs: "0",
                                                    sm: "24px",
                                                },
                                                display: {
                                                    xs: "flex",
                                                    sm: "block",
                                                },
                                                width: "100%",
                                                justifyContent: "space-between",
                                                alignItems: "end",
                                            }}
                                        >
                                            <Box>
                                                <Typography
                                                    sx={{
                                                        fontWeight: 600,
                                                        fontSize: {
                                                            xs: "16px",
                                                            md: "20px",
                                                        },
                                                        color: "#161616",
                                                        marginBottom: "8px",
                                                    }}
                                                >
                                                    {cycle.label}
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        fontWeight: 400,
                                                        fontSize: {
                                                            xs: "14px",
                                                            md: "16px",
                                                        },
                                                        color: "#4f4f4f",
                                                    }}
                                                >
                                                    {cycle.description}
                                                </Typography>
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: {
                                                        xs: "block",
                                                        sm: "none",
                                                    },
                                                }}
                                            >
                                                <Checkbox
                                                    checked={isSelected}
                                                    sx={{
                                                        padding: "0",
                                                    }}
                                                    checkedIcon={
                                                        <Box
                                                            sx={{
                                                                width: "24px",
                                                                height: "24px",
                                                            }}
                                                        >
                                                            <img
                                                                src="/images/icon-check-primary.png"
                                                                alt="aviron membership"
                                                                style={{
                                                                    width: "100%",
                                                                    height: "100%",
                                                                    objectFit:
                                                                        "cover",
                                                                }}
                                                            />
                                                        </Box>
                                                    }
                                                    icon={
                                                        <RadioButtonUncheckedIcon
                                                            sx={{
                                                                width: "24px",
                                                                height: "24px",
                                                            }}
                                                        />
                                                    }
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            marginTop: {
                                                xs: "16px",
                                                md: "0px",
                                            },
                                        }}
                                    >
                                        {cycle.special && (
                                            <Box
                                                sx={{
                                                    background:
                                                        "linear-gradient(360deg, #F5490F 24.22%, #F57D0F 100%)",
                                                    padding: "8px 12px",
                                                    fontSize: {
                                                        xs: "12px",
                                                        md: "16px",
                                                    },
                                                    color: "#fff",
                                                    fontWeight: 600,
                                                    borderRadius: "100px",
                                                    minWidth: {
                                                        xs: "auto",
                                                        sm: "102px",
                                                        md: "135px",
                                                    },
                                                    marginRight: "16px",
                                                }}
                                            >
                                                {cycle.special}
                                            </Box>
                                        )}
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "baseline",
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: {
                                                        xs: "20px",
                                                        md: "32px",
                                                    },
                                                    fontWeight: 500,
                                                    fontFamily: "MonoSpec",
                                                    color: "#161616",
                                                }}
                                            >
                                                <span>$</span>
                                                 {handleCalculatePrice(
                                                    cycle as any
                                                )}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: {
                                                        xs: "14px",
                                                        md: "16px",
                                                    },
                                                    fontWeight: 400,
                                                    color: "#4f4f4f",
                                                }}
                                            >
                                                /{cycle.unit}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        );
                    })}

                    {isShowOtherOptions && (
                        <Box
                            sx={{
                                margin: {
                                    xs: "16px 0 32px",
                                    md: "24px 0 40px",
                                },
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                            }}
                        >
                            <img
                                src="/images/icon-double-down.png"
                                alt="membership upgrade aviron"
                            />
                            <Typography
                                sx={{
                                    fontWeight: 400,
                                    color: "#ff4106",
                                    fontSize: {
                                        xs: "14px",
                                        md: "16px",
                                    },
                                    marginLeft: "4px",
                                }}
                                onClick={() => {
                                    setIsShowOtherOptions((prev) => !prev);
                                }}
                            >
                                See other options
                            </Typography>
                        </Box>
                    )}
                    {Boolean(!expireLifeTimeWarranty) && isFreeMembership && (
                        <Box>
                            <Typography
                                sx={{
                                    fontWeight: 700,
                                    fontFamily: "GRIFTER",
                                    letterSpacing: "0.01em",
                                    fontSize: {
                                        xs: "18px",
                                        md: "20px",
                                    },
                                    marginBottom: {
                                        xs: "16px",
                                        md: "24px",
                                    },
                                    marginTop: {
                                        xs: "32px",
                                        md: "40px",
                                    },
                                }}
                            >
                                ADD-ON
                            </Typography>
                            <Box
                                sx={{
                                    borderRadius: "8px",
                                    cursor: "pointer",
                                    width: {
                                        xs: "100%",
                                        md: "60%",
                                    },
                                }}
                                onClick={() => {
                                    setIsLifetimeWarranty((prev) => !prev);
                                }}
                            >
                                <Box
                                    sx={{
                                        background:
                                            "linear-gradient(360deg, #F5490F 24.22%, #F57D0F 100%)",
                                        padding: "6px 0",
                                        borderTopLeftRadius: "8px",
                                        borderTopRightRadius: "8px",
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            color: "#fff",
                                            textAlign: "center",
                                            fontWeight: 600,
                                            fontSize: {
                                                xs: "12px",
                                                md: "16px",
                                            },
                                        }}
                                    >
                                        OFFER EXPIRES ON{" "}
                                   
                                        {moment(webMembershipDetail?.startDay || new Date()).add("35", "days").format("MMM D, YYYY")?.toUpperCase()}
                                    </Typography>
                                </Box>

                                <Box
                                    sx={{
                                        border: isLifetimeWarranty
                                            ? "2px solid #FF4106"
                                            : "1px solid #C3C3C3",
                                        borderBottomLeftRadius: "8px",
                                        borderBottomRightRadius: "8px",

                                        display: "flex",
                                        flexDirection: {
                                            xs: "column",
                                            sm: "row",
                                        },
                                        justifyContent: "space-between",
                                        alignItems: {
                                            xs: "flex-start",
                                            sm: "center",
                                        },
                                        padding: {
                                            xs: "16px 12px",
                                            md: "24px",
                                        },
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            width: "100%",
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: {
                                                    xs: "none",
                                                    sm: "block",
                                                },
                                            }}
                                        >
                                            <Checkbox
                                                checked={isLifetimeWarranty}
                                                sx={{
                                                    padding: "0",
                                                }}
                                                checkedIcon={
                                                    <Box
                                                        sx={{
                                                            width: "24px",
                                                            height: "24px",
                                                        }}
                                                    >
                                                        <img
                                                            src="/images/icon-check-primary.png"
                                                            alt="aviron membership"
                                                            style={{
                                                                width: "100%",
                                                                height: "100%",
                                                                objectFit:
                                                                    "cover",
                                                            }}
                                                        />
                                                    </Box>
                                                }
                                                icon={
                                                    <RadioButtonUncheckedIcon
                                                        sx={{
                                                            width: "24px",
                                                            height: "24px",
                                                        }}
                                                    />
                                                }
                                            />
                                        </Box>

                                        <Box
                                            sx={{
                                                marginLeft: {
                                                    xs: "0",
                                                    sm: "24px",
                                                },
                                                display: {
                                                    xs: "flex",
                                                    sm: "block",
                                                },
                                                width: "100%",
                                                justifyContent: "space-between",
                                                alignItems: "end",
                                            }}
                                        >
                                            <Box>
                                                <Typography
                                                    sx={{
                                                        fontWeight: 600,
                                                        fontSize: {
                                                            xs: "16px",
                                                            md: "20px",
                                                        },
                                                        color: "#161616",
                                                        marginBottom: "8px",
                                                    }}
                                                >
                                                    LIFE TIME WARRANTY
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        fontWeight: 400,
                                                        fontSize: {
                                                            xs: "14px",
                                                            md: "16px",
                                                        },
                                                        color: "#4f4f4f",
                                                    }}
                                                >
                                                    Billed according to your membership type
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        fontWeight: 400,
                                                        fontStyle: "italic",
                                                        fontSize: {
                                                            xs: "14px",
                                                            md: "16px",
                                                        },
                                                        textDecoration:
                                                            "underline",
                                                        display: "inline-block",
                                                    }}
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        upgradePlanRef.current?.setIsVisible(
                                                            true
                                                        );
                                                    }}
                                                >
                                                    See what's cover
                                                </Typography>
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: {
                                                        xs: "block",
                                                        sm: "none",
                                                    },
                                                }}
                                            >
                                                <Checkbox
                                                    checked={isLifetimeWarranty}
                                                    sx={{
                                                        padding: "0",
                                                    }}
                                                    checkedIcon={
                                                        <Box
                                                            sx={{
                                                                width: "24px",
                                                                height: "24px",
                                                            }}
                                                        >
                                                            <img
                                                                src="/images/icon-check-primary.png"
                                                                alt="aviron membership"
                                                                style={{
                                                                    width: "100%",
                                                                    height: "100%",
                                                                    objectFit:
                                                                        "cover",
                                                                }}
                                                            />
                                                        </Box>
                                                    }
                                                    icon={
                                                        <RadioButtonUncheckedIcon
                                                            sx={{
                                                                width: "24px",
                                                                height: "24px",
                                                            }}
                                                        />
                                                    }
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            marginTop: {
                                                xs: "16px",
                                                md: "0px",
                                            },
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "baseline",
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: {
                                                        xs: "20px",
                                                        md: "32px",
                                                    },
                                                    fontWeight: 500,
                                                    fontFamily: "MonoSpec",
                                                    color: "#161616",
                                                }}
                                            >
                                                $9
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: {
                                                        xs: "14px",
                                                        md: "16px",
                                                    },
                                                    fontWeight: 400,
                                                    color: "#4f4f4f",
                                                }}
                                            >
                                                /month
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    )}

                    <Button
                        sx={{
                            backgroundColor: "#FF4106",
                            "&:hover": {
                                backgroundColor: "#FF4106",
                            },
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "20px",
                            color: "#fff",
                            marginTop: {
                                xs: "64px",
                                md: "40px",
                            },
                            width: {
                                xs: "100%",
                                md: "240px",
                            },
                            cursor: Boolean(!nextPlanData)
                                ? "not-allowed"
                                : "pointer",
                        }}
                        disabled={Boolean(!nextPlanData)}
                        onClick={handleNextChangePlan}
                    >
                        NEXT
                    </Button>
                    <UpgradePlanModal ref={upgradePlanRef} />
                </Box>
            </Box>
        </Box>
    );
};

export default MembershipUpgradePlanPage;
