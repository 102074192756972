import React, {
    Ref,
    forwardRef,
    useImperativeHandle,
    useMemo,
    useState,
} from "react";
import { makeStyles } from "@mui/styles";
import Close from "@mui/icons-material/Close";

import styles from "./UpgradePlanModal.module.scss";
import { Backdrop, Fade, IconButton, Modal } from "@mui/material";

const useStyles = makeStyles({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
});

interface IUpgradePlanModalProps {}

export interface IUpgradePlanModalRef {
    setIsVisible: (visible: boolean) => void;
    isVisible: boolean;
}

const UpgradePlanModal = (
    props: IUpgradePlanModalProps,
    ref: Ref<IUpgradePlanModalRef>
) => {
    const options = useMemo(
        () => [
            {
                id: 1,
                title: "Defects in Materials or Workmanship",
                description: `We'll repaid or replace any defective parts for your equipment.`,
            },
            {
                id: 2,
                title: "Lifetime Coverage on Key Parts",
                description: "Includes the screen, belt, frame, and more.",
            },
            {
                id: 3,
                title: "Advanced Replacement",
                description:
                    "Free advanced shipping on replacement parts or repairs.",
            },
            {
                id: 4,
                title: "Lost or Stolen Machine",
                description: "Coverage for unexpected losses.",
            },
        ],
        []
    );

    const noteList = useMemo(
        () => [
            {
                id: 1,
                description:
                    "Your warranty is active as long as your membership payments are up to date.",
            },
            {
                id: 2,
                description:
                    "If you have more than one machine, the warranty covers your primary machine and any additional machines with memberships linked to the same email address.",
            },
            {
                id: 3,
                description:
                    "All this for just $9/month, billed according to your membership type—and you can cancel anytime!",
            },
        ],
        []
    );
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const classes = useStyles();

    const handleClose = () => {
        setIsVisible(false);
    };

    useImperativeHandle(
        ref,
        () => ({
            isVisible,
            setIsVisible,
        }),
        [isVisible]
    );

    return (
        <Modal
            open={isVisible}
            onClose={handleClose}
            className={classes.modal}
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={isVisible}>
                <div className={styles["upgrade_plan_modal"]}>
                    <div className={styles["upgrade_plan_modal-header"]}>
                        <IconButton
                            onClick={handleClose}
                            className="close-button"
                        >
                            <Close />
                        </IconButton>
                    </div>
                    <div className={styles["upgrade_plan_modal-content"]}>
                        <h3 className={styles["upgrade_plan_modal-title"]}>
                            WHAT’S COVERED UNDER AVIRON LIFETIME WARRANTY?
                        </h3>
                        <div className={styles["upgrade_plan_list"]}>
                            {options.map((option) => (
                                <div
                                    key={option.id}
                                    className={styles["upgrade_plan_item"]}
                                >
                                    <img
                                        src="/images/icon-check.png"
                                        alt="membership upgrade aviron"
                                        width={24}
                                        height={24}
                                    />
                                    <div className={styles["option_text"]}>
                                        <h4
                                            className={
                                                styles["option_text-title"]
                                            }
                                        >
                                            {option.title}
                                        </h4>
                                        <p
                                            className={
                                                styles[
                                                    "option_text-description"
                                                ]
                                            }
                                        >
                                            {option.description}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className={styles["upgrade_plan_notes"]}>
                            <p className={styles["note_title"]}>
                                A FEW THINGS TO KEEP IN MIND
                            </p>
                            <ul className={styles['note_list']}>
                                {noteList.map((note) => (
                                    <li key={note.id}
                                    className={styles["note_item"]}
                                    >{note.description}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </Fade>
        </Modal>
    );
};

export default forwardRef(UpgradePlanModal);
