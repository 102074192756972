import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import React, { FunctionComponent, memo, useContext, useEffect, useMemo, useState } from "react";

import "chartjs-plugin-annotation";
import ChartComponent from "react-chartjs-2";

import useWindowSize from "Hooks/useWindowSize";
import { CONSTANTS } from "consts";
import { UserAppContext } from "contexts";
import maxBy from "lodash/maxBy";
import {
    convertKilometerToMile,
    convertMeterToKilometer,
    convertMeterToMile,
} from "utils/convertMeasure";
import "./workoutAreaChart.scss";
import { convertedSecondToTime, converTimeToSecond } from "consts/dateTime";

type PropsType = {
    // For metric select
    handleSelect: (option: number) => void;
    metricOptions?: { label: string; value: number }[];
    currentSelect: number | null;
    isStravaConnected?: boolean;
    isLogbookConnected?: boolean;
    isFitbitConnected?: boolean;
    handleSyncToStrava?: () => void;
    handleSyncToLogbook?: () => void;
    handleSyncToFitbit?: () => void;
    chartData: {
        data: { x: number; y: number }[];
        avgData: number | undefined;
        information: {
            chartName: string;
            paraName: string;
            unit: string;
            isStravaSynced: boolean;
            isLogbookSynced: boolean;
            isFitbitSynced: boolean;
            game: {
                id: number;
                name: string;
                type: string;
                time: string;
                level: string;
                category: string;
            };
        };
    };
    workoutDetail: any;
};

const convertedTime = (timeInSec: number) => {
    if (!timeInSec) return "00:00";
    let minute = Math.floor(timeInSec / 60);
    let second = Math.round(timeInSec % 60);
    if (second === 60) {
        minute++;
        second = 0;
    }
    const minuteStr = minute >= 10 ? String(minute) : `0${String(minute)}`;
    const secondStr = second >= 10 ? String(second) : `0${String(second)}`;
    return `${minuteStr}:${secondStr}`;
};

const WorkoutAvironTreadmillChart: FunctionComponent<PropsType> = ({
    chartData,
    handleSelect,
    currentSelect,
    workoutDetail,
}) => {
    const { workoutState } = useContext(UserAppContext);
    const { data: dataC, information, avgData: avgDataC } = chartData;
    const metricOptionsInit = [
        {
            "value": 0,
            "label": "OUTPUT (KJ)"
        },
        {
            "value": 1,
            "label": "WATTS"
        },
        {
            "value": 2,
            "label": "CALORIES"
        },
        {
            "value": 3,
            "label": "INCLINE"
        },
        {
            "value": 4,
            "label": "METERS"
        },
        {
            "value": 5,
            "label": "SPLIT"
        },
        {
            "value": 6,
            "label": "PACE"
        },
    ];
    const [metricOptions, setMetricOptions] = useState(metricOptionsInit)
    useEffect(()=>{
        if(workoutDetail && workoutDetail?.heartRates) {
            var sumHeartRates = workoutDetail?.heartRates.reduce((accumulator: number, currentValue: number) => {
                return accumulator + currentValue
              },0);
              if(sumHeartRates > 0) {
                setMetricOptions([
                    {
                        "value": 0,
                        "label": "OUTPUT (KJ)"
                    },
                    {
                        "value": 1,
                        "label": "WATTS"
                    },
                    {
                        "value": 2,
                        "label": "CALORIES"
                    },
                    {
                        "value": 3,
                        "label": "INCLINE"
                    },
                    {
                        "value": 4,
                        "label": "METERS"
                    },
                    {
                        "value": 5,
                        "label": "SPLIT"
                    },
                    {
                        "value": 6,
                        "label": "PACE"
                    },
                    {
                        "value": 7,
                        "label": "HEART RATE"
                    },
                ])
              } else {
                setMetricOptions(metricOptionsInit)
              }
        } else {
            setMetricOptions(metricOptionsInit)
        }
    }, [workoutDetail])
    const getMaxValueYChartPace = (data: any) => {
        if(workoutState.currentUnit === 'miles') {
            //@ts-ignore
            const maxValue = Math.round(Math.max(maxBy(data, 'y')?.y) / 60 / CONSTANTS.UNIT_CONVERT.KILOMETER_CONVERT_TO_MILE_VALUE);
            for(let i=1; i <= 5; i++) {
                let res = maxValue + i;
                if(res % 5 ===0) {
                    return res
                }
            }
        } else if (workoutState.currentUnit === 'kilometers') {
            //@ts-ignore
            const maxValue = Math.round(Math.max(maxBy(data, 'y')?.y)/60);
            for(let i=1; i <= 5; i++) {
                let res = maxValue + i;
                if((res) % 5 ===0) {
                    return res
                }
            }
        }
    }

    const formatDataChart = (isSplitMode: boolean, dataBase: any) => {
        let data = dataBase;
        if (
            currentSelect === CONSTANTS.WORKOUT_PARAMETER.kDIndexMeters
        ) {
            data = data.map((item: any) => {
                let yValue = item.y;
                switch (workoutState.currentUnit) {
                    case "miles":
                        yValue = convertMeterToMile(item.y, 0, false, true);
                        break;
                    case "kilometers":
                        yValue = convertMeterToKilometer(
                            item.y,
                            0,
                            false,
                            true
                        );
                        break;
                    default:
                        yValue = item.y;
                        break;
                }
                return { ...item, y: yValue };
            });
            // console.log("yMileaBikeData", data);
        } else if (isSplitMode) {
            let res = [];
            for (let i = 0; i < data.length; i++) {
                const item = data[i];
                res.push({
                    x: item.x,
                    y: workoutState.currentUnit === "miles"
                            ? convertKilometerToMile(item.y)
                            : item.y,
                });
            }
            return res;
        } else if (currentSelect === CONSTANTS.WORKOUT_PARAMETER.kDIndexSPM) {
            let res = [];
            //@ts-ignore
            const maxY: number = getMaxValueYChartPace(data);
            for (let i = 0; i < data.length; i++) {
                const item = data[i];
                res.push({
                    x: item.x,
                    y: item.y===0 ? 0 : formatPaceItem(item.y, maxY),
                });
            }
            return res;
        }
        return data;
    };
    const formatPaceItem = (yItem: number, maxY: number) => {
        if(maxY - yItem/60 <= 0) {
            return 0;
        } else {
            if(workoutState.currentUnit === "miles") {
                return maxY - ((yItem/CONSTANTS.UNIT_CONVERT.KILOMETER_CONVERT_TO_MILE_VALUE)/60)
            } else {
                return maxY - yItem/60
            }
        }
    }
    const formatAvgData = (avgDataC: number) => {
        const appId = localStorage.getItem("appId");
        if (
            appId === String(CONSTANTS.APP.AVIRON_APK) &&
            currentSelect === CONSTANTS.WORKOUT_PARAMETER.kDIndexSecPer500
        ) {
            if (workoutState.currentUnit === "miles") {
                return convertKilometerToMile(avgDataC);
            } else {
                return avgDataC;
            }
        } else {
            return avgDataC;
        }
    };
    const windowSize = useWindowSize();
    const isSplitMode =
        currentSelect === CONSTANTS.WORKOUT_PARAMETER.kDIndexSecPer500;
    const isHeartRate =
        currentSelect === CONSTANTS.WORKOUT_PARAMETER.kDIndexHeartRate;
    const avgData = isHeartRate ? undefined : avgDataC;
    const data: any = formatDataChart(isSplitMode, dataC);

    // console.log({dataC, avgData})
    //@ts-ignore
    const maxYValue = Math.max(avgData || 0, maxBy(data, "y")?.y || 0);
    // set another line for avgData
    const getAvgDataChart = () => {
        if(avgData && data) {
            if (isSplitMode) {
                let res = avgData;
                if(workoutState.currentUnit === "miles") {
                    res = Number(convertKilometerToMile(avgData, 10))
                }
                return [
                    { x: 0, y: res },
                    { x: data[data.length - 1].x, y: Math.floor(res  * 10)/10 },
                ]
            } else if(currentSelect===CONSTANTS.WORKOUT_PARAMETER.kDIndexSPM) {
                const maxValue = getMaxValueYChartPace(dataC) || 0;
                if(workoutState.currentUnit === "miles") {
                    const res =  maxValue - avgData/CONSTANTS.UNIT_CONVERT.KILOMETER_CONVERT_TO_MILE_VALUE/60;
                    return [
                        { x: 0, y: res },
                        { x: data[data.length - 1].x, y: res },
                    ]
                } else {
                    const res =  maxValue - avgData/60;
                    return [
                        { x: 0, y: res },
                        { x: data[data.length - 1].x, y: res },
                    ]
                }
            } else {
                return [
                    { x: 0, y: avgData },
                    { x: data[data.length - 1].x, y: avgData },
                ]
            }
        } else {
            return []
        }
    }

    const appId = localStorage.getItem("appId");

    const chartComponentData = (canvas: any) => {
        // create gradient for chart
        const ctx = canvas.getContext("2d");
        // console.log('ctx', ctx)
        const gradient = ctx.createLinearGradient(0, 0, 0, 500);
        gradient.addColorStop(1, "rgba(255, 255, 255, 0.1)");
        gradient.addColorStop(0.6, "rgba(245, 87, 15, 0.42)");
        gradient.addColorStop(0, "rgba(245, 125, 15, 1)");
        const gradientAvg = ctx.createLinearGradient(0, 0, 0, 500);
        gradientAvg.addColorStop(0, "rgba(0, 0, 0, 0.2)");
        gradientAvg.addColorStop(1, "rgba(255, 255, 255, 0.2)");
        return {
            labels: ["Average", information.paraName],
            datasets: [
                {
                    label: `Average: ${Math.round(avgData || 0)}`,
                    showLine: avgData ? true : false,
                    data: [...getAvgDataChart()],
                    pointRadius: 0,
                    pointHoverRadius: 0,
                    pointHitRadius: 10,
                    borderWidth: 0,
                    lineTension: 0,
                    borderColor: "rgba(244, 244, 244, 0)",
                    backgroundColor: gradientAvg,
                },
                {
                    label: information.paraName,
                    showLine: true,
                    data: [...data],
                    borderColor: "#F5490F",
                    backgroundColor: gradient,
                    lineTension: 0.1,
                    borderWidth: 2,
                    pointRadius: 0,
                    pointHoverRadius: 0,
                    pointHitRadius: ctx.canvas.clientHeight,
                },
            ],
        };
    };

    const calculateStepSize = (maxValue: number) => {
        const startRange = 60;
        if (maxValue / startRange < 10) {
            return startRange;
        } else {
            return (
                startRange * Math.round(Math.round(maxValue / startRange) / 5)
            );
        }
    };

    const getMaxXTick = () => {
        const max = data[data.length - 1].x;
        return max;
    };

    const getXStepSize = () => {
        const maxXValue = data[data.length - 1].x;
        if (window.innerWidth < 500) {
            if (maxXValue < 1) {
                return undefined;
            }
            if (maxXValue < 2) {
                return 0.5;
            }
            if (maxXValue < 5) {
                return 1;
            }
            if (maxXValue > 300) {
                return calculateStepSize(maxXValue);
            }
            return Math.round(maxXValue / 6);
        }
        if (maxXValue > 300) {
            return calculateStepSize(maxXValue);
        }
        return undefined;
    };

    const getYStepSize = () => {
        if (isSplitMode) {
            return undefined;
        } 
        else if(currentSelect===CONSTANTS.WORKOUT_PARAMETER.kDIndexSPM) {
            return getStepSizeChartPace()
        } 
        else {
            if (maxYValue <= 5) {
                return 1;
            } else {
                // if (window.innerWidth < 500) {
                    if (maxYValue < 1) {
                        return 0.2;
                    }
                    if (maxYValue < 2) {
                        return 0.5;
                    }
                    if (maxYValue < 5) {
                        return 1;
                    }
                    return Math.round(maxYValue / 5);
                // }
                // return undefined;
            }
        }
    };

    const getStepSizeChartPace = () => {
        const maxY = getMaxValueYChartPace(dataC) || 0;
        return maxY / 5;
    }

    const getObjStepSizeChartPace = () => {
        let resObj = {
            step0: 0,
        };
        const maxY = getMaxValueYChartPace(dataC) || 0;
        const stepValue = maxY / 5;
        let i = 0;
        do {
            //@ts-ignore
            resObj['step' + i] = maxY - i
            i = i + stepValue;
        } while (i <= maxY)
        return resObj;
    }

    const getMaxValueY = () => {
        if (currentSelect===CONSTANTS.WORKOUT_PARAMETER.kDIndexSPM) {
            return getMaxValueYChartPace(dataC)
        } 
        else {
            return undefined;
        }
    };
    const getAvgLabelValue = () => {
        if (avgData) {
            if (isSplitMode) {
                let res = 0;
                if(workoutState.currentUnit === "miles") {
                    res = Number(convertKilometerToMile(maxYValue))
                } else {
                    res = avgData;
                }
                return res
            } else if(currentSelect===CONSTANTS.WORKOUT_PARAMETER.kDIndexSPM) {
                const maxValue = getMaxValueYChartPace(dataC) || 0;
                if(workoutState.currentUnit === "miles") {
                    return maxValue - avgData/CONSTANTS.UNIT_CONVERT.KILOMETER_CONVERT_TO_MILE_VALUE/60;
                } else {
                    return maxValue - avgData/60;
                }
            } else if(currentSelect===CONSTANTS.WORKOUT_PARAMETER.kDIndexCalPerHour) {
                if (avgData / maxYValue < 0.01) {
                    const value = calculateStepSize(maxYValue);
                    return value / 3;
                }
                return avgData;
            }
            else {
                if (Math.round(maxYValue) - avgData <= 5) {
                    return Math.floor(maxYValue * 0.95)
                } 
                if (Math.floor(avgData / maxYValue) <= 0.01) {
                    const value = calculateStepSize(maxYValue);
                    return value / 3;
                }
                return avgData;
            }
        } else if(avgData===0) {
            if (currentSelect===CONSTANTS.WORKOUT_PARAMETER.kDIndexCalPerHour) {
                return 0.05;
            }
        }
    };

    const renderLabelButton = (label: string) => {
        if (label === "SPM") {
            return "PACE";
        } else if (label === "SPLIT") {
            switch (workoutState.currentUnit) {
                case "miles":
                    return "MPH";
                case "kilometers":
                    return "KPH";
                default:
                    return label;
            }
        } else if (label === "METERS") {
            switch (workoutState.currentUnit) {
                case "miles":
                    return "MILES";
                case "kilometers":
                    return "KILOMETERS";
                default:
                    return label;
            }
        } else if (label === "CALS/HOUR") {
            return 'INCLINE (%)'
        }
        return label;
    };

    const getAvgLabelContent = () => {
        if (avgData) {
            if (isSplitMode) {
                return `AVERAGE: ${
                    workoutState.currentUnit === "miles"
                        ? convertKilometerToMile(avgData, 10)
                        : Math.floor(avgData * 10) / 10
                }`;
            } else if(currentSelect===CONSTANTS.WORKOUT_PARAMETER.kDIndexSPM) {
                if(workoutState.currentUnit === "miles") {
                    return `AVERAGE: ${convertedTime(avgData/CONSTANTS.UNIT_CONVERT.KILOMETER_CONVERT_TO_MILE_VALUE)}`;
                } else {
                    return `AVERAGE: ${convertedTime(avgData)}`;
                }
            } else if(currentSelect===CONSTANTS.WORKOUT_PARAMETER.kDIndexCalPerHour) {
                return `AVERAGE: ${(Math.floor(avgData *10)/10).toLocaleString("en", {
                    minimumFractionDigits: 1,
                })}`;
            } 
            else {
                return `AVERAGE: ${Math.round(avgData)}`;
            }
        } else if(avgData === 0) {
            if(currentSelect===CONSTANTS.WORKOUT_PARAMETER.kDIndexCalPerHour) {
                return `AVERAGE: 0.0`;
            }
        } 
    };


    const getYUnitTooltipValue = (value: number) => {
        if (currentSelect === CONSTANTS.WORKOUT_PARAMETER.kDIndexMeters) {
            return (Math.round(value *100)/100).toLocaleString("en", {
                minimumFractionDigits: 2,
            });
        } else if(currentSelect === CONSTANTS.WORKOUT_PARAMETER.kDIndexSecPer500) {
            return (Math.floor(value *10)/10).toLocaleString("en", {
                minimumFractionDigits: 1,
            });
        } else if (
            currentSelect === CONSTANTS.WORKOUT_PARAMETER.kDIndexKiloJoules ||
            currentSelect === CONSTANTS.WORKOUT_PARAMETER.kDIndexCalories
        ) {
            return Math.floor(value);
        } else if (
            currentSelect === CONSTANTS.WORKOUT_PARAMETER.kDIndexCalPerHour
        ) {
            return (Math.floor(value * 10)/10).toLocaleString("en", {
                minimumFractionDigits: 1,
            });
        } else if (
            currentSelect === CONSTANTS.WORKOUT_PARAMETER.kDIndexSPM
        ) {
            //@ts-ignore
            const maxY = getMaxValueYChartPace(dataC) || 0;
            return value > 0 ? convertedTime((maxY - value) * 60) : '--.--';
        }
        else {
            return Math.round(value);
        }
    };

    const options: any = {
        layout: {
            padding: {
                right: 20, // Set the padding on the right side of the chart
            },
        },
        legend: {
            display: false,
        },
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 1,
        // Plugin
        annotation: {
            events: ["mouseenter", "mouseover"],
            drawTime: "afterDatasetsDraw",
            annotations: [
                {
                    type: "line",
                    mode: "horizontal",
                    // scaleID: avgData ? "y-axis-1" : "y-axis-0",
                    scaleID: "y-axis-1",
                    value: getAvgLabelValue(),
                    borderColor: "transparent",
                    label: {
                        backgroundColor: "rgba(0, 0, 0, 0.8)",
                        fontFamily: "Work Sans",
                        fontSize: 12,
                        fontColor: "#FFFFFF",
                        xPadding: 20,
                        yPadding: 8,
                        cornerRadius: 40,
                        enabled: true,
                        fontStyle: "normal",
                        content: getAvgLabelContent(),
                        position: "left",
                        xAdjust: 50,
                    },
                },
            ],
        },
        scales: {
            yAxes: [
                {
                    ticks: {
                        min: 0,
                        // JUST FOR THIS SecPer500!
                        max: getMaxValueY(),
                        callback: (value: any, index: any, values: any) => {
                            if (currentSelect === CONSTANTS.WORKOUT_PARAMETER.kDIndexSPM) {
                                const stepObj = getObjStepSizeChartPace();
                                console.log('stepObj', stepObj);
                                //@ts-ignore
                                return stepObj['step' + value]
                            }
                            if (windowSize.width <= 500) {
                                if (value >= 1000) {
                                    return (
                                        (value / 1000)
                                            .toFixed(1)
                                            .replace(".0", "") + "K"
                                    );
                                }
                            }
                            return value;
                        },
                        stepSize: getYStepSize(),
                        fontFamily: "MonoSpec",
                        padding: 5,
                    },
                    scaleLabel: {
                        display: false,
                        labelString: renderLabelButton(information.unit),
                        fontSize: window.innerWidth < 500 ? 12 : 16,
                    },
                    gridLines: {
                        drawTicks: false,
                    },
                },
            ],

            xAxes: [
                {
                    scaleLabel: {
                        display: true,
                        labelString: "Time",
                        fontSize: window.innerWidth < 500 ? 12 : 16,
                        fontWeight: "600",
                        color: "#939393",
                    },
                    ticks: {
                        callback: (
                            tick: string,
                            index: number,
                            values: any[]
                        ) => {
                            // console.log({ values, tick, index });
                            if (index === values.length - 1) {
                                return null;
                            } else if (
                                Math.floor(values[1] % 60) === 0 &&
                                Math.floor(Number(tick) % 60) === 0 &&
                                Math.floor(values[1]) >= 60
                            ) {
                                return `${Number(tick) / 60}min`;
                            } else if (Number(tick) < 1) {
                                if (Number(tick) === 0) {
                                    return "0S";
                                }
                                return `${Number(tick).toFixed(1)}s`;
                            } else {
                                return `${Number(tick).toFixed(0)}s`;
                            }
                        },
                        min: 0,
                        max: getMaxXTick(),
                        stepSize: getXStepSize(),
                        fontFamily: "MonoSpec",
                        fontSize: 12,
                        padding: 10,
                    },
                    gridLines: {
                        drawTicks: false,
                        display: false,
                    },
                },
            ],
        },
        showAllTooltips: true,
        tooltips: {
            callbacks: {
                label: (context: any) => {
                    if (context.datasetIndex === 1) {
                        return ` ${renderLabelButton(
                            information.unit
                        )}: ${getYUnitTooltipValue(context.yLabel)}`;
                    }
                    return "";
                },
                footer: (contextArr: any) => {
                    const context = contextArr[0];
                    if (context.datasetIndex === 1) {
                        return `     TIME: ${convertedTime(context.xLabel)}`;
                    }
                },
            },
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            footerFontStyle: "normal",
        },
    };

    const isActive = (item: number) => {
        return currentSelect === item;
    };

    const handleSelectOption = (item: any) => {
        const domTarget = document.getElementById(
            `workout-chart-option-${item.value}`
        );
        if (domTarget && window.innerWidth < 900) {
            domTarget.scrollIntoView({
                block: "center",
                inline: "center",
                behavior: "smooth",
            });
        }
        handleSelect(item.value);
    };

    const renderTypeGameLabel = (label: string) => {
        if (label.includes("METERS")) {
            const labelArr = label.split(" ");
            const number = Number(labelArr[0]);
            let numberRes;
            switch (workoutState.currentUnit) {
                case "miles":
                    numberRes = `${convertMeterToMile(
                        number,
                        100,
                        true
                    )} MILES`;
                    break;
                case "kilometers":
                    numberRes = `${convertMeterToKilometer(
                        number,
                        100,
                        true
                    )} KILOMETERS`;
                    break;
                default:
                    numberRes = `${convertMeterToMile(
                        number,
                        100,
                        true
                    )} MILES`;
                    break;
            }
            return numberRes;
        }
        else if (label.includes("KPH")) {
            const labelArr = label.split(" ");
            const number = Number(labelArr[0]);
            let numberRes;
            switch (workoutState.currentUnit) {
                case "miles":
                    numberRes = `${convertKilometerToMile(
                        number,
                        10,
                        true
                    )} MPH`;
                    break;
                case "kilometers":
                    numberRes = `${Math.floor(number * 10)/10} KPH`;
                    break;
                default:
                    numberRes = `${Math.floor(number * 10)/10} KPH`;
                    break;
            }
            return numberRes;
        }
        else if (label.includes("PACE")) {
            const labelArr = label.split("PACE");
            const number = converTimeToSecond(labelArr[0]);
            let numberRes;
            switch (workoutState.currentUnit) {
                case "miles":
                    numberRes = `${convertedSecondToTime(number / CONSTANTS.UNIT_CONVERT.KILOMETER_CONVERT_TO_MILE_VALUE)} PACE`;
                    break;
                case "kilometers":
                    numberRes = label;
                    break;
                default:
                    numberRes = label;
                    break;
            }
            return numberRes;
        }
        return label;
    };
    return (
        <Box className="workout-chart-component">
            <Stack
                direction="row"
                mb="16px"
                mt="16px"
                overflow="auto"
                display="flex"
                justifyContent="space-between"
                className={"stackTitleWrapper"}
            >
                <div className="title-container">
                    <div className="chart-name">{workoutDetail?.name}</div>
                    <div className="unitWrapper">
                        {workoutDetail?.tag?.map(
                            (item: string, index: number) => (
                                <div className="unit-name" key={index}>
                                    {renderTypeGameLabel(item)}
                                </div>
                            )
                        )}
                    </div>
                </div>
            </Stack>
            <Stack
                direction="row"
                spacing={{
                    xs: "8px",
                    md: "8px",
                }}
                mb="16px"
                overflow="auto"
                display="flex"
                sx={{
                    flexWrap: {
                        xs: "nowrap",
                        md: "wrap",
                    },
                    justifyContent: {
                        xs: "space-between",
                        md: "start",
                    },
                    paddingBottom: {
                        xs: "4px",
                        md: 0,
                    },
                    "::-webkit-scrollbar": {
                        display: "none",
                    },
                }}
                className={"tabChartButtons"}
            >
                {metricOptions.map((item) => {
                    return (
                        <div
                            key={item.value}
                            id={`workout-chart-option-${item.value}`}
                        >
                            <Button
                                onClick={handleSelectOption.bind(null, item)}
                                variant="outlined"
                                sx={{
                                    borderColor: isActive(item.value)
                                        ? "#161616 !important"
                                        : "#d1d1d1 !important",
                                    color: isActive(item.value)
                                        ? "#161616 !important"
                                        : "rgb(79, 79, 79) !important",
                                    fontWeight: isActive(item.value)
                                        ? 600
                                        : 400,
                                }}
                                className={item.value===7 ? 'heartRateButton' : ''}
                            >
                                {renderLabelButton(item.label)}
                            </Button>
                        </div>
                    );
                })}
            </Stack>
            <div className="workoutAreaChartWrapper">
                <div className="workout-area-chart-label-y">
                    {renderLabelButton(information.unit)}
                </div>
                <div className="workoutAreaChartContent">
                    <ChartComponent
                        type="scatter"
                        data={chartComponentData}
                        options={options}
                    />
                </div>
            </div>
        </Box>
    );
};
export default memo(WorkoutAvironTreadmillChart);
