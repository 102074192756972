import React, { useEffect, useMemo, useState } from "react";
import Swal from "sweetalert2";
import { ENV } from "scripts/environment";
import {
    useChangeUserSubscriptionPlanMutation,
    usePlansQuery,
} from "services/webServer";
import { MEMBERSHIP_INTERVAL, MembershipProductType } from "consts/membership";
import maxBy from "lodash/maxBy";
import { handleCheckIsFreeMembership } from "../utils/handleCheckIsFreeMembership";
import { CONSTANTS } from "consts";

const BILLING_CYCLE = [
    {
        key: "annual",
        interval: MEMBERSHIP_INTERVAL.YEARLY,
        intervalCount: 1,
        label: "ANNUAL",
        description: "Billed annually",
        price: "$24",
        unit: "month",
        special: "SAVE 2 MONTHS",
    },
    {
        key: "2-years",
        interval: MEMBERSHIP_INTERVAL.YEARLY,
        intervalCount: 2,
        label: "2 YEARS",
        description: "Billed every two years",
        price: "$24",
        unit: "month",
        special: "SAVE 4 MONTHS",
    },
    {
        key: "monthly",
        interval: MEMBERSHIP_INTERVAL.MONTHLY,
        intervalCount: 1,
        label: "MONTHLY",
        description: "Billed monthly",
        price: "$24",
        unit: "month",
    },
];

export function useUpgradeMembershipPlan({
    gameMembershipDetail,
    webMembershipDetail,
}: any) {
    const { data: planData, loading: loadingPlan } = usePlansQuery();
    const [billingCycle, setBillingCycle] = useState(BILLING_CYCLE[0]);
    const [isLifetimeWarrantySelected, setIsLifetimeWarrantySelected] =
        useState(false);
    const [machineCount, setMachineCount] = useState("1");
    const [changeUserSubscription] = useChangeUserSubscriptionPlanMutation();

    const isFreeMembership = handleCheckIsFreeMembership(gameMembershipDetail.planId);

    const availableBillingCycles = useMemo(() => {
        console.log("webMembershipDetail", webMembershipDetail)
        const isTwoYear = webMembershipDetail?.plan?.name?.includes("2 Year");
        const isGoModel = webMembershipDetail?.plan?.appIds?.length > 0 ? webMembershipDetail?.plan?.appIds?.includes(CONSTANTS.APP.AVIRON_GO) : false
        const isYearly = webMembershipDetail?.plan?.interval === MEMBERSHIP_INTERVAL.YEARLY;
        console.log("isGoModel", isGoModel)

        if(isFreeMembership) return BILLING_CYCLE;

        if(isTwoYear) {
            return BILLING_CYCLE.filter((cycle) => cycle.key === "2-years");
        }
        if(isGoModel) {
            if(isYearly) return [];
            // Go Monthly
            return BILLING_CYCLE.filter((cycle) => cycle.key === "annual");
        }
        if (
            isYearly
        ) {
            return BILLING_CYCLE.filter((cycle) => cycle.key !== "monthly");
        }
      
        return BILLING_CYCLE
    }, [isFreeMembership, webMembershipDetail]);

    const canEnrollLifetimeWarranty = isFreeMembership; // Now we only allow customer to enroll ltw for free memberships

    const plans = planData?.plans?.nodes || [];

    const newPlan = useMemo(() => {
        const selectedPlanProductId = canEnrollLifetimeWarranty
            ? isLifetimeWarrantySelected
                ? MembershipProductType.membershipWithWarranty
                : MembershipProductType.membership
            : webMembershipDetail.plan.productId;
        return plans.find((plan) => {
            return (
                plan.id !== webMembershipDetail.plan.id &&
                plan.customerType === webMembershipDetail.plan.customerType &&
                plan.interval === billingCycle.interval &&
                plan.intervalCount === billingCycle.intervalCount &&
                plan.maxMachines === Number(machineCount) &&
                plan.type === "stripe" &&
                plan.enable &&
                plan.currency === webMembershipDetail.plan.currency &&
                plan.productId === selectedPlanProductId &&
                webMembershipDetail.plan.appIds.some((appId: number) => {
                    return plan.appIds?.includes(appId);
                })
            );
        });
    }, [
        plans,
        webMembershipDetail,
        billingCycle,
        machineCount,
        isLifetimeWarrantySelected,
    ]);

    const availableMaxMachines = useMemo(() => {
        const planHasMaxValue = maxBy(
            plans.filter(
                (plan) =>
                    plan.interval === billingCycle.interval &&
                    plan.intervalCount === billingCycle.intervalCount
            ),
            "maxMachines"
        );
        return planHasMaxValue?.maxMachines;
    }, [billingCycle, plans]);

    const handleChangePlan = async () => {
        const successFunc = async function () {
            await Swal.fire(
                "Success",
                "Membership plan changed successfully.",
                "success"
            );
            // refetch();
            // setTimeout(() => {
            //     refetchReceipts({
            //         id: params.id,
            //         first: ROWS_PER_PAGE,
            //         offset: 0,
            //     });
            // }, 2000);
            window.location.reload();
        };
        const errorFunc = async (
            message: string = "Change membership plan failed"
        ) => {
            await Swal.fire("Error", message, "error");
            // refetch();
            // refetchReceipts({
            //     id: params.id,
            //     first: ROWS_PER_PAGE,
            //     offset: 0,
            // });
            window.location.reload();
        };
        try {
            if (!newPlan) {
                await Swal.fire("Error", "newPlan undefined", "error");
                return;
            }
            const { data } = await changeUserSubscription({
                variables: {
                    id: webMembershipDetail.id,
                    planId: newPlan?.id as number,
                },
            });
            if (
                data &&
                data?.changeUserSubscriptionPlan &&
                (data as any).changeUserSubscriptionPlan.error
            ) {
                errorFunc(
                    "Can't charge your card. We will open a new tab automatically to retry payment for membership. Please reload page after payment successful."
                );
                // setState(
                //     Object.assign(state, {
                //         change_plan_invoice_url: (data as any)
                //             .changeUserSubscriptionPlan.error.invoice
                //             .hosted_invoice_url,
                //         processing_change_plan: false,
                //     })
                // );
                setTimeout(() => {
                    window.open(
                        (data as any)?.changeUserSubscriptionPlan?.error.invoice
                            .hosted_invoice_url
                    );
                }, 3000);
            } else {
                successFunc();
            }
        } catch (err) {
            errorFunc();
        } finally {
            // setState({
            //     ...state,
            //     processing_change_plan: false,
            // });
        }
    };

    const currentPlan = useMemo(()=>{
        return plans.find(
            (plan) =>
                plan.id ===
                Number(
                    isFreeMembership
                        ? webMembershipDetail.remark?.upcomingPlanId
                        : webMembershipDetail.planId
                )
        );
    }, [plans, webMembershipDetail, isFreeMembership]);

    useEffect(() => {
        if (currentPlan) {
            setBillingCycle(
                BILLING_CYCLE.find(
                    (cycle) =>
                        cycle.interval === currentPlan.interval &&
                        cycle.intervalCount === currentPlan.intervalCount
                ) as any
            );
            setMachineCount(String(currentPlan.maxMachines))
        }
    }, [currentPlan]);

    return {
        handleChangeBillingCycle: setBillingCycle,
        handleChangeMachineCount: setMachineCount,
        handleSelectLifetimeWarranty: setIsLifetimeWarrantySelected,
        billingCycle,
        isLifetimeWarrantySelected,
        machineCount,
        availableBillingCycles,
        canEnrollLifetimeWarranty,
        newPlan,
        handleChangePlan,
        isFreeMembership,
        currentPlan,
        availableMaxMachines,
    };
}
