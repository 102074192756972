import { Backdrop, Button, Fade, IconButton, Modal } from "@mui/material";
import React, {
    Ref,
    forwardRef,
    useContext,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from "react";
import { makeStyles } from "@mui/styles";
import Close from "@mui/icons-material/Close";

import { UserAppContext } from "contexts";
import { MEMBERSHIP_OFFER_KEY } from "../MembershipsMain.constant";
import moment from "moment";
import {
    useCreateAnnualSubscriptionWithDiscountMutation,
    useGetLatestSubscriptionByUserQuery,
    useRetriveStripeCustomerQuery,
    useSharedQuery,
} from "services/webServer";
import get from "lodash/get";
import MembershipOfferInvalidPaymentModal, {
    IMembershipOfferInvalidPaymentModalRef,
} from "../MembershipOfferInvalidPaymentModal/MembershipOfferInvalidPaymentModal";
import MembershipOfferClaimedModal, {
    IMembershipOfferClaimedModalRef,
} from "../MembershipOfferClaimedModal/MembershipOfferClaimedModal";

import styles from "./MembershipOfferModal.module.scss";

const useStyles = makeStyles({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
});

interface IMembershipOfferModalProps {
    offer: any
}

export interface IMembershipOfferModalRef {
    setIsVisible: (visible: boolean) => void;
    isVisible: boolean;
}
const dataMoreDetail = [
    {
        id: 1,
        text: "Once you click the button below, your new discounted membership will be purchased.",
    },
    {
        id: 2,
        text: "You won’t be charged immediately; billing will begin upon activation of your new membership.",
    },
    {
        id: 3,
        text: "To activate, simply log in to your current account on your machine.",
    },
    {
        id: 4,
        text: "Once activated, you can cancel your membership at any time.",
    },
];

const MembershipOfferModal = (
    {offer}: IMembershipOfferModalProps,
    ref: Ref<IMembershipOfferModalRef>
) => {
    const [isVisible, setIsVisible] = useState<boolean>(false);

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const classes = useStyles();
    const { auth } = useContext(UserAppContext);
    const { data } = useSharedQuery();
    const {data: lastestSubscription} = useGetLatestSubscriptionByUserQuery({
        variables:{
            userId: auth?.id
        },
        fetchPolicy: "no-cache",
        skip: Boolean(!auth?.id) ,
        onError:() => {
            setIsLoading(false);
        },
        onCompleted() {
            setIsLoading(false);
        },
    })
    const [subscriptionId, setSubscriptionId] = useState<string>("");

    const [createAnnualSubscriptionWithDiscount] =
        useCreateAnnualSubscriptionWithDiscountMutation();

    const membershipInvalidPaymentRef =
        useRef<IMembershipOfferInvalidPaymentModalRef>(null);

    const membershipClaimedRef = useRef<IMembershipOfferClaimedModalRef>(null);

    const stripeCustomerId = get(
        data,
        "currentUser.profile.stripe.customer_id"
    );
    
    const { data: customerData } = useRetriveStripeCustomerQuery({
        variables: {
            customerId: stripeCustomerId,
            params: {
                "expand[]": "invoice_settings.default_payment_method",
            },
        },
        fetchPolicy: "no-cache",
        skip: Boolean(!stripeCustomerId)
    });

    const cardInfo = useMemo(() => {
        if (!customerData || !customerData?.retriveStripeCustomer)
            return undefined;

        const defaultPaymentInfo =
            customerData?.retriveStripeCustomer?.invoice_settings
                ?.default_payment_method || undefined;

        if (defaultPaymentInfo) {
            return {
                exp_month: defaultPaymentInfo?.card?.exp_month,
                exp_year: defaultPaymentInfo?.card?.exp_year,
            };
        }
        return undefined;
    }, [customerData]);

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const handleClose = () => {
        setIsVisible(false);
    };

    const handleCheckCardExpired = ({
        expMonth,
        expYear,
    }: {
        expMonth: number;
        expYear: number;
    }) => {
        if (!expMonth || !expYear) return true;

        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() + 1;
        const currentYear = currentDate.getFullYear();

        return (
            expYear < currentYear ||
            (expYear === currentYear && expMonth < currentMonth)
        );
    };

    const currency = useMemo(() => {
        let defaultCurrency = "USD";
        if(lastestSubscription?.getLatestSubscriptionByUser?.currency){
            defaultCurrency = lastestSubscription?.getLatestSubscriptionByUser?.currency || "USD"
        }
        return defaultCurrency?.toUpperCase();
    }, [lastestSubscription]);

    const handleClickRedeemOffer = async () => {
        const isExpired = handleCheckCardExpired({
            expMonth: cardInfo?.exp_month,
            expYear: cardInfo?.exp_year,
        });
        if (isExpired) {
            setIsVisible(false);
            membershipInvalidPaymentRef?.current?.setIsVisible(true);
            return;
        }

        if (!auth?.email || Boolean(isSubmitting)) return;
        try {
            setIsSubmitting(true);
            const result = await createAnnualSubscriptionWithDiscount({
                variables: {
                    email: auth?.email,
                    currency: currency,
                },
            });
            const subscriptionId =
                result?.data?.createAnnualSubscriptionWithDiscount?.subscription
                    ?.id;
            setIsVisible(false);
            setIsSubmitting(false);

            if (subscriptionId) {
                setSubscriptionId(subscriptionId);
                membershipClaimedRef?.current?.setIsVisible(true);
            }
        } catch (error) {
            setIsSubmitting(false);
            setIsVisible(false);
        }
    };



    useImperativeHandle(
        ref,
        () => ({
            isVisible,
            setIsVisible,
        }),
        [isVisible]
    );

    if(isLoading) return <></>

    return (
        <>
            <Modal
                open={isVisible}
                onClose={handleClose}
                className={classes.modal}
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={isVisible}>
                    <div className={styles["membership_offer_modal"]}>
                        <div
                            className={styles["membership_offer_modal-header"]}
                        >
                            <IconButton
                                onClick={handleClose}
                                className="close-button"
                            >
                                <Close />
                            </IconButton>
                        </div>

                        <div
                            className={styles["membership_offer_modal-content"]}
                        >
                            <div className={styles["header"]}>
                                <div>
                                    <img
                                        src="/images/aviron-logo.png"
                                        alt="Aviron Logo"
                                        width={129}
                                        height={25}
                                        style={{
                                            objectFit: "contain"
                                        }}
                                    />
                                    <h2
                                        className={
                                            styles["membership_offer-title"]
                                        }
                                    >
                                        WELCOME BACK{data?.currentUser?.firstName && ","}{" "}
                                        <span className={styles["highlight"]}>
                                            {data?.currentUser?.firstName || ""}
                                        </span>
                                    </h2>
                                    <p
                                        className={
                                            styles[
                                                "membership_offer-description"
                                            ]
                                        }
                                    >
                                        50% OFF <br />
                                        FOR A YEAR
                                    </p>
                                </div>
                                <img
                                    src="/images/membership/membership-offer.webp"
                                    alt="Aviron Membership Offer"
                                    className={styles["membership_offer-image"]}
                                />
                            </div>
                            <div className={styles["content"]}>
                                <div
                                    className={styles["membership_offer-data"]}
                                >
                                    <div>
                                        <p className={styles["data-tag"]}>
                                            OFFER EXPIRES ON{" "}
                                            {moment(
                                                offer?.expires || new Date()
                                            ).format("MMMM DD, YYYY")}
                                        </p>
                                        <p className={styles["data-title"]}>
                                            ANNUAL FAMILY MEMBERSHIP
                                        </p>
                                        <p
                                            className={
                                                styles["data-description"]
                                            }
                                        >
                                            {currency === "USD" ? "$174/year": "$216/year" }, billed annually
                                        </p>
                                    </div>
                                    <div>
                                        <p className={styles["price_original"]}>
                                             {currency === "USD" ? "$29.00": "$36.00" }
                                        </p>
                                        <p className={styles["price_discount"]}>
                                            {currency === "USD" ? "$14.50": "$18.00" }/
                                            <span className={styles["unit"]}>
                                                MONTH
                                            </span>
                                        </p>
                                    </div>
                                    <div className={styles["currency"]}>
                                        {currency}
                                        <img
                                            src="/images/icon-down.png"
                                            alt="Aviron Membership Offer"
                                            width={8}
                                            height={4}
                                            style={{
                                                marginLeft: "8px",
                                                marginBottom: "3px",
                                            }}
                                        />
                                    </div>
                                </div>
                                <ul
                                    className={
                                        styles["membership_offer_more-info"]
                                    }
                                >
                                    {dataMoreDetail.map((item) => (
                                        <li
                                            className={
                                                styles[
                                                    "membership_offer_more-info-item"
                                                ]
                                            }
                                            key={item.id}
                                        >
                                            {item.text}
                                        </li>
                                    ))}
                                </ul>
                                <div
                                    className={
                                        styles["membership_offer-button"]
                                    }
                                >
                                    <Button
                                        className={styles["btn-claim-offer"]}
                                        variant="contained"
                                        onClick={handleClickRedeemOffer}
                                        color="secondary"
                                        sx={{
                                            marginTop: "24px",
                                            marginBottom: "8px",
                                            fontSize: "16px",
                                        }}
                                    >
                                        {isSubmitting
                                            ? "REDEEMING..."
                                            : "REDEEM OFFER"}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
            <MembershipOfferInvalidPaymentModal
                ref={membershipInvalidPaymentRef}
            />
            <MembershipOfferClaimedModal
                ref={membershipClaimedRef}
                id={subscriptionId}
            />
        </>
    );
};

export default forwardRef(MembershipOfferModal);
